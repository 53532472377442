:root {
  --orange: #CC9459;
  --blue: #1e3045;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  background-color: #f4f4f4;
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.6em;
  margin: 0;
}

.container, .navigation {
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.container .logo {
  color: #1e3045;
  margin-right: 2px;
}

#main-header {
  background-color: #CC9459;
  color: #fff;
  display: flex;
}

#main-header #username {
  margin-right: 30px;
  margin-top: 20px;
  font-size: 25px;
}

#main-header .container {
  margin: 0 30px;
}

#navbar {
  background-color: #1e3045;
  color: #fff;
}

#navbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#navbar ul li {
  display: inline-block;
  padding: 10px;
  margin: 0;
}

#navbar .navigation {
  padding: 0;
  margin: 0 0 0 20px;
}

#navbar a {
  color: #fff;
  text-decoration: none;
  font-size: 22px;
  transition-property: all;
  transition-duration: 0.05s;
}

#navbar a:hover,
#navbar ul li:hover,
.dash-nav:hover { 
  background: #304c6d;
  color: #CC9459;
}

.dash-nav {
  font-size: 25px;
  background: var(--blue);
  color: #fff;
  margin-right: 5px;
  border-radius: 3px;
}

#showcase, #champlain-showcase {
  background: url('images/vegas.jpg') no-repeat center center/cover;
  min-height: 300px;
  margin-bottom: 30px;
  text-align: center;
}

#showcase h1, #champlain-showcase h1{
  color: #fff;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 1.6em;
  padding-top: 30px;
}

#champlain-showcase {
  background-image: url('images/showcase.jpg');
}

#main,
#main-gallery {
  width: 100%;
  padding: 0 10px;
}

#main-gallery {
  float: none;
  width: 100%;
}

#main ul {
  padding: 0;
  margin: 0;
}

.recipe {
  text-decoration: none;
  color: #333;
}

.recipe-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  background-color: var(--orange);
  color: var(--blue);
}

#recipe-home {
  background: var(--orange);
  text-align: center;
  margin: 0 20px;
  border-radius: 8px;
}

#recipe-home a {
  color: var(--blue);
  text-decoration: none;
}

.edit-box.active {
  display: flex;
}

.edit-box {
  display: none;
}

#main .post {
  border-bottom: #ddd solid 2px;
  padding: 10px 0;
}

#sidebar{
  float: right;
  width: 40%;
  background: #182637;
  color: #fff;
  padding: 5px;
  box-sizing: border-box;
}

#sidebar h2 {
  padding: 10px;
  margin: 10px;
  text-align: center;
}

#main-footer {
  background: #1e3045;
  color: #fff;
  text-align: center;
  padding: 0px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Gallery full screen slide show */
.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(30, 48, 69, 0.95);
  z-index: 20;
}

.fullscreen-container.active {
  display: flex;
}

.fullscreen-category {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}

.fullscreen-desc,
.fullscreen-title {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
}

.fullscreen-title {
  bottom: 8%;
  margin-bottom: 20px;
}

.fullscreen-desc {
  bottom: 5%;
  font-size: 1.2rem;
  margin-top: 10px;
}

.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
}

.gallery-main.active {
  display: block;
}

.gallery-main {
  display: none;
}

.arrow {
  position: fixed;
  background-color: rgba(30, 48, 69, 0.55);
  color: #fff;
  padding: 20px;
  font-size: 30px;
  border: 2px solid var(--orange);
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.left-arrow {
  left: calc(7.5vw - 65px);
}

.right-arrow {
  right: calc(7.5vw - 65px);
}

.fullscreen-back-button {
  top: 40px;
  left: 20px;
  font-size: 15px;
  padding: 5px;
  width: 70px;
  border-radius: 20px;
  border-width: 1px;
}

.photos {
  display: flex;
  overflow: scroll hidden;
  width: 100%;
}

.photo-container {
  cursor: pointer;
}

.photos .photo {
  max-width: 400px;
  max-height: 300px;
  margin: 5px;
}

.journal-photo {
  max-height: 150px;
  max-width: 150px;
}

.post-photos .photo {
  max-width: 300px;
  max-height: 200px;
}

.app-link {
  border: #555 solid 1px;
  padding: 5px 20px;
  border-radius: 8px;
  background: #1D3557;
}

.fa-circle {
  font-size: 0.4rem;
}

#main iframe {
  border: 0;
}

.image-col {
  display: flex;
  flex-wrap: wrap;
}

.image-col .recipe-image {
  max-width: 300px;
  max-height: 200px;
}

.btn-recipe {
  background-color: var(--orange);
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
}

.videos {
  display: flex;
  overflow: auto;
  width: 70vw;
}

.container h3 {
  margin: 10px 0;
  text-decoration: underline;
}

.video {
  margin: 5px 10px;
}

#video-home{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#photo-title {
  text-align: center;
}

#photo-desc {
  padding: 0 30px;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
}

.hidden {
  display: none;
}

.visible { 
  display: inline-block;
}

#recipe-title {
  margin-top: 20px;
}

.sub-rec-lists {
  display: flex;
  flex-wrap: wrap;
}

.sub-rec-lists h5 {
  text-align: center;
}

.sub-rec-lists ul {
  padding: 0;
}

.rec-list {
  margin: 10px 20px 10px 0;
}

.sub-recipe-list {
  display: flex;
}

.sub-recipe-list .sub-recipe {
  margin-right: 30px;
}

.sub-recipe-list .sub-recipe ul {
  padding: 0;
}

.recipe-list {
  padding-left: 10px;
  margin-left: 30px;
}

.recipe-print {
  background: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.ingredients {
  display: flex;
  flex-wrap: wrap;
}

.ingredients li {
  margin: 0 5px;
  min-width: 45%;
  max-width: 45%;
}

.recipe-display, 
.recipe-print {
  display: none;
}

.recipe-display.active,
.recipe-print.active {
  display: block;
}

.post h5 {
  margin-top: 10px;
}

.post p {
  margin-top: 15px;
  white-space: pre-wrap;
}

.post-photos {
  overflow: wrap;
}

.file-input {
  background: var(--blue);
  color: #fff;
}

.add-photo {
  background: var(--orange);
  color: var(--blue);
  padding: 20px 10px;
  border-radius: 20px;
}

.button {
  background: var(--blue);
  border: none;
}

.journal-table {
  width: 100%;
  table-layout: fixed;
}

@media(max-width: 1200px) {
  iframe {
    width: 336px;
    height: 189px;
}
}

@media(max-width: 998px) {
  
  #main-header {
      text-align: center;
  }

  #showcase {
      background: url('images/vegas.jpg') no-repeat center center;
      min-height: 300px;
      margin-bottom: 30px;
      text-align: center;
  }

  #showcase h1, #champlain-showcase h1{
      color: #fff;
      text-transform: uppercase;
      line-height: 1.6em;
      padding-top: 30px;
  }

  #navbar.active li {
      display: list-item;
  }

  #navbar.active li a {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.2em;
  }

  #navbar.active li a:hover {
      font-size: 3.2rem;
  }

  .navigation {
      display: none;
  }

  .navigation.active {
      display: list-item;
  }

  .toggle {
      left: 0;
      width: 60px;
      height: 60px;
      background: url('images/menu.png');
      z-index: 20;
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: center;
      transform: scale(1.7);
      cursor: pointer;
  }
  
  .toggle.active {
      background: url('images/close.png');
      background-size: 25px;
      margin-bottom: 60px;
      z-index: 20;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
  }

  /* Remove floats and change width to 100% */
  #main {
      width: 100%;
      float: none;
      padding: 0;
  }

  #main h1 {
      font-size: 40px;
      line-height: 1.3em;
  }

  #main p {
      font-size: 20px;
      line-height: 1.6em;
  }

  #main ul li {
      margin: 10px 0;
      font-size: 1.2rem;
  }

  #main h3 {
      margin-top: 50px;
      margin-bottom: 15px;
      text-decoration: underline;
  }

  #main iframe {
      width: 352px;
      height: 198px;
  }

  #sidebar {
      width: 100%;
      float: none;
  }
  
  .left-arrow {
    left: calc(7.5vw - 30px);
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .right-arrow {
    right: calc(7.5vw - 30px);
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media print {
  .printable {
    width: 100%;
    margin: 0 auto;
    font-size: 12pt;
  }

  @page {
    size: A4;
    margin: 10mm;
  }

  .page-break {
    page-break-before: always;
  }

  .avoid-break {
    page-break-inside: avoid;
  }
  
  .non-printable {
    display: none;
  }
}

